import React from 'react';
import { Navigation } from "./navigation";

export const Products = () => {

  return (
    <div>
      <Navigation/>
      <pre className="code-snippet">
      <code>  <rentle-store shop="justbefun" /></code>
        </pre>
      <div className>

      </div>
    </div>
    
  );
};

export default Products;
