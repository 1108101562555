import React, { useEffect, useState } from 'react';
import imageSrc from "./transparentlogo.png";

export const Navigation = (props) => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      setIsScrolled(scrollTop > 200);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  const iconStyle = {
    width: '180',
    height: '180',
    margin: '0',
    padding: '0',
    background: 'transparent'
  };
  const navBarStyle = {
    backgroundColor: isScrolled ? 'white' : 'transparent',
    margin: '0',
    padding: '0',
    background: 'transparent',
    "box-shadow":0 
  };
  const navBarRightStyle = {
    color: 'white',
    "font-weigth": 'bold',
  };

  const [showLogo, setShowLogo] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      setShowLogo(scrollTop < 100);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <div style={navBarStyle}  className="general-navbar">


 <nav id="menu" className="navbar navbar-default navbar-fixed-top" style={navBarStyle}>

<div 
className="container"
>

  <div 
  className="navbar-header"

  >
    <button
      type="button"
      className="navbar-toggle collapsed"
      data-toggle="collapse"
      data-target="#bs-example-navbar-collapse-1"
    >
      {" "}
      <span className="sr-only">Toggle navigation</span>{" "}
      <span className="icon-bar"></span>{" "}
      <span className="icon-bar"></span>{" "}
      <span className="icon-bar"></span>{" "}
    </button>
  </div>

  <div
    className="collapse navbar-collapse"
    id="bs-example-navbar-collapse-1"
  >

{showLogo && <img src={imageSrc} alt="Image" className="navbar-brand page-scroll" href="#page-top" style={iconStyle} />}{" "}

    <ul className="nav navbar-nav navbar-right" style={navBarRightStyle}>
    <li>
        <a href="http://localhost:3000/#features" className="page-scroll">
          Why choose us?
        </a>
      </li>
      <li>
        <a href="#about" className="page-scroll">
          About
        </a>
      </li>
      <li>
        <a href="#portfolio" className="page-scroll">
          Gallery
        </a>
      </li>
      <li>
        <a href="#testimonials" className="page-scroll">
          Testimonials
        </a>
      </li>
      <li>
        <a href="#team" className="page-scroll">
          FAQ
        </a>
      </li>
      <li>
        <a href="#contact" className="page-scroll">
          Contact
        </a>
      </li>
    </ul>
  </div>
</div>
</nav>
    </div>
    
  );
};
