import React, { useState } from 'react';
import './Team.css';

export const Team = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const questions = [
    {
      question: 'How does the rental process work?',
      answer: 'The rental process is simple and convenient. First, browse our wide selection of party equipment available for rent on our website. Once you have chosen the items you need, select the desired rental period and add them to your cart. Proceed to checkout, where you will provide your contact and payment information. We will confirm your reservation and arrange for delivery or pickup of the equipment based on your preference. After your event, simply return the rented items according to the agreed-upon schedule.      ',
    },
    {
      question: 'Are delivery and pickup included in the rental service?',
      answer: 'You can install ReactJS using the npm package manager.',
    },
    {
      question: 'What happens if the rented equipment gets damaged?',
      answer: 'JSX is a syntax extension for JavaScript that allows you to write HTML-like code in your JavaScript files.',
    },
    {
      question: 'Can I cancel or modify my rental order?',
      answer: 'React Hooks are functions that allow you to use state and other React features without writing a class.',
    },
    {
      question: 'What is the refund policy for cancelled order?',
      answer: 'React Hooks are functions that allow you to use state and other React features without writing a class.',
    },
  ];

  const handleQuestionClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div id="team" className="text-center">
    <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>Frequency asked questions</h2>
        </div>
        <div className="col-md-8 col-md-offset-2 section-title">
        {questions.map((q, index) => (
        <div key={index} className="faq-item">
          <div className="faq-question" onClick={() => handleQuestionClick(index)}>
            <span>{q.question}</span>
            <i className={`fa ${activeIndex === index ? 'fa-minus' : 'fa-plus'}`} />
          </div>
          {activeIndex === index && <div className="faq-answer">{q.answer}</div>}
        </div>
      ))}
        </div>
      </div>
    </div>
  );
};

export default Team;
